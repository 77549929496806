import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import ImageFooted from '../../../components/widgets/imageFooted';
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyCertificados: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce - Certificados SAT" description="Agregar tus certificados CSD a Shopify para poder facturar con FiscalPOP" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Certificados SAT
            </h2>
            <p>
                Para poder facturar tendrás que subir tus certificados del SAT a la applicación, a continuación detallamos los pasos para realizarlo en la app
                de FiscalPOP.
            </p>
            <p>
                Podrás utilizar la app aunque no tengas certificados, esto te permite familiarizarte con la APP y configurar productos (opcional) mientras
                obtienes tu CSD. Sin embargo, no podrás facturar en línea o en Admin hasta que tus certificados estén agregados.
            </p>
            <p><span className="quote small">Si tienes tu FIEL pero nunca has generado un CSD, entra <Link to="/api/guia">aquí para saber como obtenerlo</Link></span></p>
            <h3>
                Para subir la llave y el certificado
            </h3>
            <ol>
                <li>
                    <p>
                        Da click en <span className="accent">Seleccionar archivo</span> y escoge tu certificado (.crt)
                    </p>
                </li>
                <li>
                    <p>
                        Una vez hayas seleccionado el archivo, da click en <span className="accent">Subir certificado</span> para que inicie el proceso.
                    </p>
                </li>
                <li>
                    <p>
                        Si el certificado que escogiste es CSD correcto, solamente verás una leyenda que es necesario subir la llave, <span className="accent">continua</span>.
                    </p>
                </li>
                <li>
                    <p>
                        Da click en <span className="accent">Seleccionar archivo</span> y escoge tu llave (.key)
                    </p>
                </li>
                <li>
                    <p>
                        Agrega la contraseña en el bloque de texto <span className="accent">Contraseña de tu llave</span>.
                    </p>
                </li>
                <li>
                    <p>
                        Una vez hayas escrito la contraseña, da click en <span className="accent">Subir llave</span> para que inicie el proceso.
                    </p>
                </li>
                <li>
                    <p>
                        Si la llave es correcta verás <span className="accent">Status</span> indicando que "la llave es válida", entonces tu llave está lista.
                    </p>
                </li>
            </ol>
            <p>
                <span className="small normal">NOTA: <span className="error">Si la llave tiene error</span>, puede ser porque la contraseña no es la correcta o la llave que usas no es del CSD, sino de tu FIEL.</span>
            </p>
            <ImageFooted explanation={`Podrás utilizar la app aunque no tengas certificados, esto te permite familiarizarte con la APP y opcionalmente configurar tus productos mientras obtienes tu CSD. Sin embargo, tus certificados son necesarios para facturar.`}>
                <IMG_Mercadolibre file={'certificates'} />
            </ImageFooted>
            <SectionNavigate next={`/ecommerce/mercadolibre/claves-default`}/>
        </div>
    </Layout>
)

export default ShopifyCertificados;